import { useSpring, animated } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { clamp } from '@kaliber/math'

import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerMd } from '/features/buildingBlocks/Container'
import { ParallaxImageCover } from '/features/buildingBlocks/ParallaxImage'

import mediaStyles from '/cssGlobal/media.css'
import gradientOverlayStyles from '/features/buildingBlocks/gradientOverlay.css'
import styles from './RevealingQuote.css'

export function RevealingQuote({ quote, layoutClassName = undefined }) {
  const { progress, trackedElementRef } = useQuoteScrollProgression()
  const quoteAsWords = quote.split(' ')

  return (
    <div
      ref={trackedElementRef}
      className={cx(styles.component, layoutClassName)}
    >
      {quoteAsWords.map((word, xid) => (
        <Word
          key={xid}
          offset={xid}
          {...{ word, progress }}
        />
      ))}
    </div>
  )
}

export function RevealingQuoteWithImage({ quote, image, layoutClassName }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)

  return (
    <div className={cx(styles.componentWithImage, gradientOverlayStyles.gradientOverlayBlack, layoutClassName)}>
      <ParallaxImageCover
        aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
        distanceOverride={isViewportLg ? 100 : 50}
        layoutClassName={styles.imageLayout}
        {...{ image }}
      />
      <div className={styles.quoteContainer}>
        <ContainerMd>
          <RevealingQuote
            layoutClassName={styles.imageQuoteLayout}
            {...{ quote }}
          />
        </ContainerMd>
      </div>
    </div>
  )
}

function Word({ word, offset, progress }) {
  const { style } = useAnimationByProgressionThreshold({ progress, offset })

  return (
    <animated.span {...{ style }}>
      {word}
    </animated.span>
  )
}

function useQuoteScrollProgression() {
  const [progress, setProgress] = React.useState(0)
  const trackedElementRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(0.20) },
    onChange(progression) {
      setProgress(progression)
    }
  })

  return {
    progress,
    trackedElementRef
  }
}

function useAnimationByProgressionThreshold({ progress, offset }) {
  const amplitude = 0.5
  const threshold = 0.7
  const calculateProgressByOffset = progress - (offset / 100) + amplitude
  const clampedProgress = clamp({ min: 0, max: 1, input: calculateProgressByOffset })

  const [style, api ] = useSpring(() => ({
    opacity: 0.1,
    config: { mass: 1, tension: 40, friction: 40 },
  }))

  const thresholdReached = clampedProgress > threshold

  React.useMemo(
    () => {
      api.start({ opacity: thresholdReached ? 1 : 0.1 })
    },
    [api, thresholdReached]
  )

  return {
    style
  }
}
