import { useTranslate } from '/machinery/I18n'
import { useSpring, animated } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { clamp, lerp } from '@kaliber/math'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { Image } from '/features/buildingBlocks/Image'
import { ButtonIconWithLabel, ButtonIcon } from '/features/buildingBlocks/Button'
import { ModalXl } from '/features/buildingBlocks/Modal'

import min from '/images/icons/icon-min.raw.svg'
import plus from '/images/icons/icon-plus.raw.svg'
import chevronLeft from '/images/icons/chevron-left.raw.svg'
import styles from './ZoomableImagePopup.css'

const maxZoomLevel = 3

export function ZoomableImagePopup({ active, onClose, image }) {
  return (
    <ModalXl
      contentContainerClass={'noPadding'}
      {...{ active, onClose }}
      content={
        <Content
          layoutClassName={styles.contentLayout}
          {...{ onClose, image }}
        />
      }
    />
  )
}

function Content({ onClose, image, layoutClassName }) {
  const [zoomLevel, setZoomLevel] = React.useState(0)

  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      {image?.asset && (
        <DraggableZoomableImage
          onZoomLevelChange={setZoomLevel}
          layoutClassName={styles.draggableZoomableImageLayout}
          {...{ image, zoomLevel }}
        />
      )}
      <ZoomControls
        onZoomLevelChange={handleZoom}
        layoutClassName={styles.controlsLayout}
        {...{ zoomLevel, maxZoomLevel }}
      />
      <CloseButton onClick={onClose} layoutClassName={styles.closeButtonLayout} />
    </div>
  )

  function handleZoom(zoomLevel) {
    setZoomLevel(clamp({ min: 0, max: maxZoomLevel, input: zoomLevel }))
    pushToDataLayer({
      event: 'interaction_clicked',
      event_data: {
        metadata: {
          interaction: {
            title: 'artwork-detail',
            type: 'zoomed'
          }
        }
      }
    })
  }
}

function DraggableZoomableImage({ image, zoomLevel, onZoomLevelChange, layoutClassName }) {
  const { animation, bindGestures } = useDragAndZoomGestures({ zoomLevel, maxZoomLevel, onZoomLevelChange })

  return (
    <div {...bindGestures()} className={cx(styles.componentDraggableZoomableImage, layoutClassName)}>
      <AnimatableImage
        {...{ image }}
        animation={{
          scale: animation.scale,
          x: animation.x.to(translateWithOffet),
          y: animation.y.to(translateWithOffet),
        }}
        layoutClassName={styles.animatableImageLayout}
      />
    </div>
  )
}

function translateWithOffet(x) {
  return `calc(-50% + ${x}px)`
}

function AnimatableImage({ image, animation, layoutClassName }) {
  return (
    <animated.div className={cx(styles.componentAnimatableImage, layoutClassName)} style={animation}>
      <Image imgProps={{ draggable: false }} layoutClassName={styles.imageLayout} {...{ image }} />
    </animated.div>
  )
}

function ZoomControls({ onZoomLevelChange, layoutClassName, zoomLevel, maxZoomLevel }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentZoomControls, layoutClassName)}>
      <ButtonIcon
        onClick={() => onZoomLevelChange(zoomLevel - 1)}
        icon={min}
        dataX='zoom-out'
        label={__`zoom-out`}
        disabled={zoomLevel === 0}
        layoutClassName={styles.zoomButtonLayout}
      />
      <ButtonIcon
        onClick={() => onZoomLevelChange(zoomLevel + 1)}
        icon={plus}
        dataX='zoom-in'
        label='zoom in'
        disabled={zoomLevel === maxZoomLevel}
        layoutClassName={styles.zoomButtonLayout}
      />
    </div>
  )
}

function CloseButton({ onClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <ButtonIconWithLabel
      dataX='close-modal'
      label={__`go-back`}
      icon={chevronLeft}
      {...{ onClick, layoutClassName }}
    />
  )
}

function useDragAndZoomGestures({ zoomLevel, maxZoomLevel, onZoomLevelChange }) {
  const zoomScale = 2 ** lerp({ start: 0, end: 2, input: zoomLevel / maxZoomLevel })
  const [animation, api] = useSpring(() => ({ x: 0, y: 0, scale: zoomScale, immediate: true }))

  // TODO: Erik - this should probably not be an effect
  React.useEffect(() => { api.start({ scale: 0.4 * zoomScale }) }, [api, zoomScale])
  // TODO: Erik - this should have bounds, you can now drag the image off-screen and never get it back
  const bindGestures = useGesture(
    {
      onDrag({ offset: [offsetX, offsetY] }) {
        api.start({ x: offsetX, y: offsetY })
      },
      // TODO: I couldn't figure out how to pinch with chrome so I couldn't test this
      onPinch({ delta: [deltaX], cancel }) {
        const newZoom = zoomLevel + (deltaX * 0.01)
        onZoomLevelChange(clamp({ min: 0, max: maxZoomLevel, input: newZoom }))
      }
    },
    {}
  )

  return { animation, bindGestures }
}
