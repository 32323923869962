import { useParams } from '/machinery/Params'

export function useArticleInfo(article, { isActiveFn }) {
  const { issue, bankcode } = useParams()
  const { content, metadata } = article
  const { hero } = content
  const { title } = hero
  const { rubric, highlighted } = metadata

  return React.useMemo(
    () => ({
      hero,
      title,
      rubric: rubric.name,
      rubricSlug: rubric.slug,
      isHighlighted: highlighted,
      isActive: isActiveFn({ issue, bankcode, rubricSlug: rubric.slug }),
      bankcode,
      issue,
    })
    ,
    [hero, title, rubric, isActiveFn, bankcode, issue, highlighted])
}
