export function mergeRefs(...refs) {
  if (!refs.length) return null
  if (refs.length === 1) return refs[0]

  const mergedRef = getMemoizedMergedRef(
    (value) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(value)
        } else if (ref !== null) {
          ref.current = value
        }
      })
    },
    refs
  )

  return mergedRef
}

getMemoizedMergedRef.cache = new WeakMap()

function getMemoizedMergedRef(value, [firstRef, ...otherRefs] = [], cache = getMemoizedMergedRef.cache) {
  if (!firstRef) return value
  if (!cache.has(firstRef)) {
    const nestedCache = new WeakMap()
    cache.set(firstRef, getMemoizedMergedRef(value, otherRefs, nestedCache))
  }

  return cache.get(firstRef)
}
