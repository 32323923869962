import { useEvent } from '/machinery/useEvent'

export function useDebounced(f, config = { interval: 300 }) {
  const intervalRef = React.useRef(null)

  return useEvent(
    (...args) => {
      clearTimeout(intervalRef.current)
      intervalRef.current = setTimeout(
        () => { f(...args) },
        config.interval
      )
    }
  )
}

export function useDebouncedValue(value, config = { interval: 500 }) {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, config.interval)

      return () => {
        clearTimeout(handler)
      }
    },
    [value, config.interval]
  )

  return debouncedValue
}
