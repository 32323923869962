import { animated } from '@react-spring/web'

import styles from './MetaTag.css'

export function MetaTag({ label }) {
  return (
    <div className={styles.component}>
      <span>{label}</span>
    </div>
  )
}

export function MetaTagButton({ label, onActiveTagChange = undefined }) {

  return (
    <button
      type='button'
      data-x='click-to-open-tag-related-articles'
      onClick={handleClick}
      className={styles.componentButton}
    >
      <span>{label}</span>
    </button>
  )

  function handleClick() {
    onActiveTagChange && onActiveTagChange(label)
  }
}

export function MetaTagWrapper({ children, animation = undefined }) {
  return (
    <animated.div style={animation} className={styles.componentWrapper}>
      {children}
    </animated.div>
  )
}
