import { Icon } from '/features/buildingBlocks/Icon'
import { ShareModalRegion, ShareModalArticle } from '/features/buildingBlocks/ShareModal'

import styles from './ShareButton.css'

import shareIcon from '/images/icons/icon-share.raw.svg'

export function ShareButton({ title, text, type, shareText, region = undefined, image = undefined, images = undefined, layoutClassName = undefined }) {
  const [showShareModal, setShowShareModal] = React.useState(false)
  const ShareModal = determineShareModalComponent(type)
  const { navigatorShareEnabled, share } = useNavigatorShare()

  return (
    <>
      <button type='button' data-x='open-share-modal' onClick={handleClick} className={cx(styles.shareButton, layoutClassName)}>
        <Icon icon={shareIcon} layoutClassName={styles.shareIconLayout} />
      </button>
      <ShareModal
        active={showShareModal}
        onClose={handleClose}
        issueImage={image}
        issueRegion={region}
        {...{ shareText, images }}
      />
    </>
  )
  function handleClose() { setShowShareModal(false) }

  function handleClick() {
    if (navigatorShareEnabled) share({ url: window.location.href })
    if (!navigatorShareEnabled) setShowShareModal(true)
  }
}

function determineShareModalComponent(type) {
  const ShareModal =
    type === 'region' ? ShareModalRegion :
    type === 'article' ? ShareModalArticle :
    throwError(`Unexpected type ${type}`)

  return ShareModal
}

/** @returns {never} */
function throwError(message) { throw new Error(message) }

function useNavigatorShare() {
  const navigatorShareEnabledRef = React.useRef(false)

  React.useEffect(() => {
    if (navigator.share) navigatorShareEnabledRef.current = true
  }, [])

  return {
    navigatorShareEnabled: navigatorShareEnabledRef.current,
    share: ({ title = undefined, text = undefined, url }) => navigatorShareEnabledRef.current && navigator.share({ title, text, url })
  }
}
