import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerSm, ContainerMd, ContainerLg } from '/features/buildingBlocks/Container'

import mediaStyles from '/cssGlobal/media.css'
import styles from './LoopingVideo.css'

/**
 *
 * @param {object} o
 * @param {string} o.src
 * @param {'square'|'portrait'|'landscape'} o.orientation
 * @returns
 */
export function LoopingVideoAll({ src, orientation }) {
  if (orientation === 'square') return <LoopingVideoSquare {...{ src }} />
  if (orientation === 'portrait') return <LoopingVideoPortrait {...{ src }} />
  if (orientation === 'landscape') return <LoopingVideoLandscape {...{ src }} />
  return <LoopingVideoSquare {...{ src }} />
}

function LoopingVideoPortrait({ src }) {
  return <LoopingVideoBase ContainerComponent={ContainerSm} className={styles.componentPortrait} {...{ src }} />
}

function LoopingVideoSquare({ src }) {
  return <LoopingVideoBase ContainerComponent={ContainerMd} className={styles.componentSquare} {...{ src }} />
}

function LoopingVideoLandscape({ src }) {
  return <LoopingVideoBase ContainerComponent={ContainerLg} className={styles.componentLandscape} {...{ src }} />
}

function LoopingVideoBase({ src, ContainerComponent, className }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const videoRef = React.useRef(null)

  const ContainerElement = isViewportMd ? ContainerComponent : React.Fragment

  React.useEffect(
    () => {
      if (!isInViewport) videoRef.current?.pause()
      else videoRef.current?.play()
    },
    [isInViewport]
  )

  return (
    <ContainerElement>
      <div ref={isInViewportRef} className={cx(styles.componentBase, className)}>
        <video
          ref={videoRef}
          loop
          muted
          autoPlay
          playsInline
          disablePictureInPicture
          controlsList="nodownload"
          className={styles.video}
          {...{ src }}
        />
      </div>
    </ContainerElement>
  )
}
