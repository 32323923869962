import { useFloating, useDismiss, useInteractions, autoUpdate } from '@floating-ui/react'
import { animated, useTransition } from '@react-spring/web'
import { useLocation } from '@kaliber/routing'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'
import { useParams } from '/machinery/Params'
import { useSignalValue } from '/machinery/signals'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Menu.css'

import mapIcon from '/images/icons/map-nl.raw.svg'
import listIcon from '/images/icons/list.raw.svg'
import deckIcon from '/images/icons/deck.raw.svg'
import searchIcon from '/images/icons/search.raw.svg'
import checkIcon from '/images/icons/check.raw.svg'

export function MenuDeckFeed({ tocDrawer, searchDrawer, currentArticleIndex$, articleCount, finishedReading$, layoutClassName }) {
  const currentArticleIndex = useSignalValue(currentArticleIndex$)
  const finishedReading = useSignalValue(finishedReading$)

  return <Menu {...{ tocDrawer, searchDrawer, currentArticleIndex, articleCount, finishedReading, layoutClassName }} />
}

export function Menu({
  tocDrawer,
  searchDrawer,
  currentArticleIndex,
  articleCount,
  finishedReading,
  layoutClassName = undefined
}) {
  const menuItems = useMenuItems({ tocDrawer, searchDrawer })
  const [isOpen, setIsOpen] = React.useState(false)
  const { refs, getReferenceProps } = useFloatingProps({ isOpen, onOpenChange: setIsOpen })

  return (
    <nav
      // We don't want these events to propagate, they could influence floaiting UI boxes that are behind the menu.
      onMouseDown={(e) =>  e.stopPropagation()}
      onPointerDown={(e) =>  e.stopPropagation()}
      onClick={(e) =>  e.stopPropagation()}
      className={cx(styles.component, layoutClassName)}
    >
      <div {...getReferenceProps()} ref={refs.setReference} className={cx(styles.menuContainer, isOpen && styles.isOpen)}>
        <Hamburger onOpenChange={setIsOpen} {...{ isOpen, currentArticleIndex, articleCount, finishedReading }} />
        <MenuList items={menuItems} {...{ isOpen }} />
      </div>
    </nav >
  )
}

function Hamburger({ isOpen, onOpenChange, currentArticleIndex, articleCount, finishedReading, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      onClick={handleClick}
      data-x='click-to-toggle-menu'
      aria-label={__`open-menu`}
      className={cx(styles.componentHamburger, layoutClassName)}
    >
      <HamburgerIcon {...{ isOpen }} />
      <div className={cx(styles.hamburgerContent, isOpen && styles.isOpen)}>
        <HamburgerContent currentIndex={currentArticleIndex} total={articleCount} {...{ finishedReading }} />
      </div>
    </button>
  )

  function handleClick() {
    onOpenChange(!isOpen)
  }
}

function HamburgerIcon({ isOpen }) {
  return <span className={cx(styles.componentHamburgerIcon, isOpen && styles.isOpen)} />
}

function HamburgerContent({ currentIndex, total, finishedReading }) {
  const { __ } = useTranslate()
  const currentIndexIsCover = currentIndex === 0

  return (
    <div className={styles.componentHamburgerContent}>
      <p className={cx(styles.menuText, currentIndexIsCover && styles.isActive)}>{__`menu`}</p>
      <Counter isActive={!currentIndexIsCover} {...{ currentIndex, total }} />
      <CheckIcon isActive={finishedReading} />
    </div>
  )
}

function Counter({ isActive, currentIndex, total }) {
  const action = useCounterAction({ currentIndex })
  const transitions = useCounterTransition({ currentIndex, action })

  return (
    <div className={cx(styles.componentCounter, isActive && styles.isActive)}>
      <span className={styles.currentContainer}>
        {transitions((style, item) => (
          <animated.span className={styles.counter} {...{ style }}>
            {item}
          </animated.span>
        ))}
      </span>
      <span>/</span>
      <span>{total}</span>
    </div>
  )
}

function CheckIcon({ isActive }) {
  return (
    <div className={cx(styles.componentCheckIcon, isActive && styles.isActive)}>
      <Icon icon={checkIcon} layoutClassName={styles.iconLayout} />
    </div>
  )
}

function MenuList({ items, isOpen }) {
  return (
    <div className={cx(styles.componentList, isOpen && styles.isOpen)}>
      <ul className={styles.list}>
        {items.map((item, i) => (
          <li
            key={i}
            style={{ '--index': i }}
            className={cx(
              styles.listItem,
              isOpen && styles.isOpen,
              item.isActive && styles.isActive
            )}
          >
            <MenuItem layoutClassName={styles.itemLayout} {...{ item }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

function MenuItem({ item, layoutClassName = undefined }) {
  const { icon } = item

  return (
    <div className={cx(styles.componentItem, layoutClassName)}>
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
      <MenuItemButton layoutClassName={styles.buttonLayout} {...{ item }} />
    </div>
  )
}

function MenuItemButton({ item,  layoutClassName = undefined }) {
  switch (item.type) {
    case 'link': return <MenuLink {...{ item, layoutClassName }} />
    case 'button': return <MenuButton {...{ item, layoutClassName }} />
    default: return null
  }
}

function MenuLink({ item, layoutClassName }) {
  const { href, label, value } = item

  return (
    <a data-x={`link-in-menu-to-${value}`} className={cx(styles.componentLink, layoutClassName)} {...{ href }}>
      {label}
    </a>
  )
}

function MenuButton({ item,  layoutClassName }) {
  const { onClick, label, value } = item

  return (
    <button
      type='button'
      data-x={`link-in-menu-to-${value}`}
      className={cx(styles.componentButton, layoutClassName)}
      {...{ onClick }}
    >
      {label}
    </button>
  )
}

function useMenuItems({ tocDrawer, searchDrawer }) {
  const { __ } = useTranslate()
  const { pathname } = useLocation()
  const { language, issue, bankcode, rubricSlug } = useParams()
  const drawerIsOpen = tocDrawer.isOpen || searchDrawer.isOpen
  const atDeckFeed = pathname === routeMap.app.issue.region({ language, issue, bankcode })

  const menuItems = [
    {
      label: __`map`,
      icon: mapIcon,
      value: 'home',
      type: 'link',
      href: routeMap.app.issue({ issue, language }),
      isActive: false,
    },
    {
      label: __`inhoud`,
      icon: listIcon,
      value: 'inhoudsopgave',
      type: 'button',
      onClick: tocDrawer.handleOpen,
      isActive: tocDrawer.isOpen
    },
    {
      label: __`overview`,
      icon: deckIcon,
      value: 'feed',
      type: atDeckFeed ? 'button' : 'link',
      onClick: handleCloseDrawers,
      href: `${routeMap.app.issue.region({ language, issue, bankcode })}#${rubricSlug}`,
      isActive: atDeckFeed && !drawerIsOpen,
    },
    {
      label: __`search`,
      icon: searchIcon,
      value: 'search',
      type: 'button',
      onClick: searchDrawer.handleOpen,
      isActive: searchDrawer.isOpen
    }
  ]

  return menuItems

  function handleCloseDrawers() {
    if (searchDrawer.isOpen) searchDrawer.handleClose()
    else if (tocDrawer.isOpen) tocDrawer.handleClose()
  }
}

function useCounterAction({ currentIndex }) {
  const previousCounterRef = React.useRef(null)
  const action = (
    previousCounterRef.current === null ? 'none' :
    previousCounterRef.current < currentIndex ? 'increase' :
    'decrease'
  )

  previousCounterRef.current = currentIndex

  return action
}

/** @param {{ currentIndex: number, action: 'none' | 'increase' | 'decrease' }} props */
function useCounterTransition({ currentIndex, action }) {
  return useTransition(currentIndex, {
    from: {
      opacity: action === 'none' ? 1 : 0,
      y: (
        action === 'increase' ? '10px' :
        action === 'decrease' ? '-10px' :
        '0px'
      ),
    },
    enter: { opacity: 1, y: '0px' },
    leave: {
      opacity: 0,
      y: (
        action === 'increase' ? '-10px' :
        action === 'decrease' ? '10px' :
        '0px'
      ),
    },
    config: { mass: 1, tension: 220, friction: 35 },
  })
}

function useFloatingProps({ isOpen, onOpenChange }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context, {
    referencePress: false,
    outsidePress: true
  })

  const { getReferenceProps } = useInteractions([dismiss])

  return { refs, getReferenceProps }
}
