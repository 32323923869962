import { useFloating, useInteractions, useDismiss, autoUpdate, shift, offset, useHover, useRole, useTransitionStyles, safePolygon, flip, size } from '@floating-ui/react'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { Icon } from '/features/buildingBlocks/Icon'

import cameraIcon from '/images/icons/camera.raw.svg'

import mediaStyles from '/cssGlobal/media.css'
import styles from './PhotoIconOverlay.css'

export function PhotoIconOverlay({ renderContent, layoutClassName = undefined }) {
  const { isOpen, styles: { transition }, getReferenceProps, getFloatingProps } = useFloatingTooltip()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <button
        type='button'
        data-x='toggle-credits'
        className={styles.cameraButton}
        {...getReferenceProps()}
      >
        <Icon icon={cameraIcon} layoutClassName={styles.iconLayout} />
      </button>

      {isOpen && (
        <Overlay
          layoutClassName={styles.overlayLayout}
          {...{ transition, renderContent }}
          {...getFloatingProps()}
        />
      )}
    </div>
  )
}

function useFloatingTooltip() {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const [isOpen, setIsOpen] = React.useState(false)

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift(), offset(12), size({ apply: applyFn })],
    strategy: 'absolute',
    placement: 'top-end',
  })

  const role = useRole(context, { role: 'tooltip' })
  const dismiss = useDismiss(context, { capture: false })
  const hover = useHover(context, { handleClose: safePolygon() })
  const { getReferenceProps, getFloatingProps } = useInteractions([role, dismiss, hover])

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: { opacity: 0, transform: 'translateY(12px)' },
    close: { opacity: 0, transform: 'translateY(12px)' },
    open: { opacity: 1, transform: 'translateY(0px)' },
  })

  return {
    isOpen: isMounted,
    styles: { floating: floatingStyles, transition: transitionStyles },
    getReferenceProps: (x = {}) =>
      getReferenceProps({ ...x, ref: refs.setReference }),
    getFloatingProps: (x = {}) =>
      getFloatingProps({ ...x, ref: refs.setFloating, style: floatingStyles })
  }

  function applyFn({ availableWidth, elements }) {
    Object.assign(elements.floating.style, {
      maxWidth: `${Math.max(isViewportMd ? window.innerWidth * 0.75 : 400, availableWidth)}px`,
    })
  }
}

const Overlay = React.forwardRef(OverlayImpl)
function OverlayImpl({ renderContent, transition, style = undefined, layoutClassName = undefined }, forwardedRef) {
  return (
    <div ref={forwardedRef} className={cx(styles.componentOverlayImpl, layoutClassName)} {...{ style }}>
      <div style={transition}>
        {renderContent()}
      </div>
    </div>
  )
}
