import { CardImageWithoutShadow } from '/features/regionArticles/cards/CardImage'
import { Icon } from '/features/buildingBlocks/Icon'

import gradientOverlayStyles from '/features/buildingBlocks/gradientOverlay.css'
import styles from './Card.css'

import plusIcon from '/images/icons/icon-plus.raw.svg'

export function CardWithBackgroundImage({
  children,
  backgroundImage,
  layoutClassName,
  contentContainerClassName = undefined, // TODO: Erik - check if you really need this -> Nope, we need container divs. The long answer is that from the calling side you do not know the behavior of this component and have no way of knowing what child properties you can give. This mechanism will cause very nasty bugs as it stands.
  gestureBindings = undefined,
  backgroundAnimation = undefined,
  hasGradientBackground = false,
}) {
  return (
    <div
      className={cx(
        styles.componentWithBackgroundImage,
        hasGradientBackground && gradientOverlayStyles.gradientOverlayBlack,
        contentContainerClassName,
        layoutClassName,
      )}
      {...gestureBindings}
    >
      {children}
      <CardImageWithoutShadow
        image={backgroundImage}
        animation={backgroundAnimation}
        layoutClassName={styles.backgroundImageLayout}
      />
    </div>
  )
}

export function CardWithGradientBackgroundGreen({ children, contentContainerClassName, layoutClassName = undefined }) {
  const props = { children, contentContainerClassName, layoutClassName }

  return <CardWithGradientBackground colorClassName={styles.green} {...props} />
}

export function CardWithGradientBackgroundOrange({ children, contentContainerClassName, layoutClassName = undefined }) {
  const props = { children, contentContainerClassName, layoutClassName }

  return <CardWithGradientBackground colorClassName={styles.orange} {...props} />
}

export function CardWithGradientBackgroundCyan({ children, contentContainerClassName, layoutClassName = undefined }) {
  const props = { children, contentContainerClassName, layoutClassName }

  return <CardWithGradientBackground colorClassName={styles.cyan} {...props} />
}

export function CardWithGradientBackgroundCyanReverse({ children, contentContainerClassName, layoutClassName = undefined }) {
  const props = { children, contentContainerClassName, layoutClassName }

  return <CardWithGradientBackground colorClassName={styles.cyanReverse} {...props} />
}

function CardWithGradientBackground({ children, colorClassName, layoutClassName, contentContainerClassName, animation = undefined }) {
  return (
    <div
      style={animation}
      className={cx(
        styles.componentWithGradientBackground,
        contentContainerClassName,
        colorClassName,
        layoutClassName
      )}
    >
      {children}
    </div>
  )
}

export function CardPlusIcon({ layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentPlusIcon, layoutClassName)} >
      <span className={styles.plusIconContainer}>
        <Icon icon={plusIcon} />
      </span>
    </div>
  )
}

export function CardPlusButtonLink({ href, layoutClassName = undefined }) {
  return (
    <a
      data-x='open-detail-pop-up'
      rel='noopener noreferrer'
      target='_self'
      aria-label='Go to article'
      draggable='false'
      className={cx(styles.componentPlusButtonLink, layoutClassName)}
      {...{ href }}
    >
      <span className={styles.plusIconContainer}>
        <Icon icon={plusIcon} />
      </span>
    </a>
  )
}
