import { useSpring } from '@react-spring/web'
import { useLocationMatch } from '@kaliber/routing'

import { useTranslate } from '/machinery/I18n'
import { useClientConfig } from '/machinery/ClientConfig'
import { useReportError } from '/machinery/ReportError'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { Icon } from '/features/buildingBlocks/Icon'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Notification } from '/features/buildingBlocks/Notification'
import { ModalXl } from '/features/buildingBlocks/Modal'

import mediaStyles from '/cssGlobal/media.css'
import styles from './ShareModal.css'

import checkmarkIcon from '/images/icons/check.raw.svg'
import linkedin from '/images/icons/linkedin.raw.svg'
import whatsapp from '/images/icons/whatsapp.raw.svg'
import mail from '/images/icons/mail.raw.svg'
import clipboard from '/images/icons/clipboard.raw.svg'
import logo from '/images/branding/rabo-en-co-logo.svg'

export function ShareModalRegion({ issueImage, issueRegion, shareText, active, onClose }) {
  return (
    <ShareModalBase {...{ active, onClose, shareText }}>
      <div className={cx(styles.componentRegion, styles.regionLayout)}>
        <ShareModalRegionContent
          layoutClassName={styles.regionShareContentLayout}
          {...{ issueImage, issueRegion }}
        />
      </div>
    </ShareModalBase>
  )
}

function ShareModalRegionContent({ issueImage, issueRegion, layoutClassName }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <div className={cx(styles.componentRegionContent, layoutClassName)}>
      <div className={styles.regionCover}>
        <div className={styles.regionCoverLogo}>
          <img src={logo} width='110px' alt="logo" />
          {issueRegion && <h3 className={styles.regionCoverIssue}>{issueRegion}</h3>}
        </div>
        {issueImage && (
          <ImageCover
            aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
            layoutClassName={styles.imageLayout}
            image={issueImage}
          />
        )}
      </div>
    </div>
  )
}

export function ShareModalArticle({ images, shareText, active, onClose }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <ShareModalBase {...{ active, onClose, shareText }}>
      <div className={styles.componentArticle}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageGrid}>
            {images.map((image, index) => (
              <ImageCover
                key={index}
                aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
                layoutClassName={styles.imageLayout}
                {...{ image }}
              />
            ))}
          </div>
        </div>
      </div>
    </ShareModalBase>
  )
}

function ShareModalBase({ children, shareText, onClose, active = false }) {
  return (
    <ModalXl {...{ active, onClose }}
      content={
        <div className={styles.shareModalBase}>
          {children}
          <ShareContainer {...{ shareText }} />
        </div>
      }
    />
  )
}

function ShareContainer({ shareText }) {
  const { __ } = useTranslate()
  const { linkedInLink, whatsAppLink, emailLink, copyUrlToClipboard, modalStyle } = useShareLinks()

  return (
    <div className={styles.componentShareContainer}>
      <p className={styles.shareHeading}>{shareText}</p>
      <div className={styles.shareOptions}>
        <a href={linkedInLink} target='_blank' data-x='share-via-linkedin' rel='noopener noreferrer' draggable='false' style={{ backgroundColor: '#0966C2' }} className={styles.shareIcon}>
          <Icon icon={linkedin} layoutClassName={styles.shareIconLayout} />
        </a>
        <a href={whatsAppLink} target='_blank' data-x='share-via-whatsapp' rel='noopener noreferrer' draggable='false' style={{ backgroundColor: '#25D366' }} className={styles.shareIcon}>
          <Icon icon={whatsapp} layoutClassName={styles.shareIconLayout} />
        </a>
        <a href={emailLink} target='_blank' data-x='share-via-email' rel='noopener noreferrer' draggable='false' style={{ backgroundColor: '#7D8E98' }} className={styles.shareIcon}>
          <Icon icon={mail} layoutClassName={styles.shareIconLayout} />
        </a>
        <button type='button' data-x='copy-url-to-clipboard' onClick={copyUrlToClipboard} draggable='false' style={{ backgroundColor: '#fff' }} className={cx(styles.shareIcon, styles.copyToClipboard)}>
          <Icon icon={clipboard} layoutClassName={styles.shareIconLayout} />
          <Notification
            layoutClassName={styles.copyToClipboardModalLayout}
            icon={checkmarkIcon}
            iconColorClassName={styles.checkmarkIconColor}
            animation={modalStyle}
            notification={__`copy-url`}
          />
        </button>
      </div>
    </div>
  )
}

function useShareLinks() {
  const reportError = useReportError()
  const { host } = useClientConfig()
  const { route, params } = useLocationMatch()
  const url = `${host}${route(params)}`

  const [{ opacity, y }, api] = useSpring(() => ({
    opacity: 0,
    y: 20,
  }))

  const linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?${new URLSearchParams({ url })}`
  const whatsAppLink = `https://api.whatsapp.com/send?${new URLSearchParams({ text: url })}`
  const emailLink = `mailto:?${new URLSearchParams({ subject: 'Lees deze Rabo&Co editie', url, body: url })}`

  return {
    linkedInLink,
    whatsAppLink,
    emailLink,
    copyUrlToClipboard,
    modalStyle: {
      opacity,
      y,
    }
  }

  async function copyUrlToClipboard() {
    try {
      await navigator.clipboard.writeText(url)
      api.start({
        from: {
          opacity: 0,
          y: 10,
        },
        to: [
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: -20,
            delay: 1000
          }
        ],
        config: { mass: 1, tension: 280, friction: 60 },
      })
    } catch (e) {
      reportError(e)
    }
  }
}
