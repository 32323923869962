import { useSpring, useTrail } from '@react-spring/web'
import { useOnSignalChange } from './signals'

export function useSignalDrivenSpring(signal$, getProps) {
  const [props, api] = useSpring(() => getProps(signal$.get()))
  useOnSignalChange({ ifChanged: signal$, callback: value => { api.start(getProps(value)) } })
  return props
}

export function useSignalDrivenTrail(length, signal$, getProps) {
  const [props, api] = useTrail(length, () => getProps(signal$.get()))
  useOnSignalChange({ ifChanged: signal$, callback: value => { api.start(getProps(value)) } })
  return props
}
