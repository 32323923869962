import { useParams } from './Params'

/** @type {React.Context<object>} */
const searchContext = React.createContext({})

export function useSearchContext() {
  return React.useContext(searchContext)
}

export function SearchContextProvider({ doc = undefined, regionData, children }) {
  const { issue = doc?.issue }  = useParams()
  const regio = regionData.bank.woodwing || regionData.bank.regio

  const data = {
    region: regio ? `Rabo en Co ${regio}` : doc?.publicationLevelName,
    issue,
    bank: regionData.bank
  }

  return <searchContext.Provider value={data} {...{ children }} />
}
