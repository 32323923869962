import { useSignalValue } from './signals'

const isActiveContext = React.createContext(null)

export function IsActiveContext({ isActive$, children }) {
  return <isActiveContext.Provider value={isActive$} {...{ children }} />
}

export function useIsActiveSignal() {
  const isActive$ = React.useContext(isActiveContext)
  if (!isActive$) throw new Error(`useIsActive used outside of IsActiveContext`)

  return isActive$
}

export function useIsActive() {
  const isActive$ = useIsActiveSignal()
  const isActive = useSignalValue(isActive$)

  return isActive
}
