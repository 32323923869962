import { useTranslate } from '/machinery/I18n'

export function Crediter({ position, name }) {
  const { __ } = useTranslate()

  return (
    <div>
      {__`${position}`}: {name}
    </div>
  )
}
