/**
 * @param {String} key
 * @returns {[ object, (o: Object) => void ]}
 */
export function useLocalStorage(key, initialValue) {
  const [data, setData] = React.useState(initialValue)

  React.useEffect(
    () => {
      const dataFromStorage = window.localStorage?.getItem(key)
      dataFromStorage && setData(JSON.parse(dataFromStorage))
    },
    []
  )

  function updateData(newDataOrFn) {
    const newData = (typeof newDataOrFn === 'function') ? newDataOrFn(data) : newDataOrFn
    setData(newData)
    window.localStorage?.setItem(key, JSON.stringify(newData))
  }

  return [ data, updateData ]
}
