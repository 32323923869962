import { useTranslate } from '/machinery/I18n'
import { animated, useSpring } from '@react-spring/web'

import { useIsActive } from '/machinery/IsActive'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './AudioPlayer.css'

import pauseIcon from '/images/icons/icon-pause.raw.svg'
import playIcon from '/images/icons/icon-play-alt.raw.svg'

// TODO: Erik / Erwin - names do not reflect what they do. Can probably be a component with 'autoPlay'
// It also poses problems, we might need an `isActive` context here to prevent problems
// Clarification: this is used from Woodwing.js which does not know about isActive
export function AudioPlayerDeckFeed({ src, layoutClassName = undefined }) {
  const isActive = useIsActive()

  return (
    <AudioPlayerBase className={cx(styles.componentDeckFeed, layoutClassName)} {...{ src, isActive }} />
  )
}

export function AudioPlayerArticlePage({ src, layoutClassName = undefined }) {
  return (
    <AudioPlayerBase className={cx(styles.componentArticlePage, layoutClassName)} {...{ src }} />
  )
}

function AudioPlayerBase({ src, isActive = undefined, className }) {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const audioRef = React.useRef(null)

  // TODO: Erik - this states: ondeactive -> pause, we probably need a mechanism for this
  React.useEffect(
    () => {
      if ((typeof isActive !== 'undefined') && (!isActive)) {
        setIsPlaying(false)
        audioRef.current.pause()
      }
    },
    [isActive]
  )

  return (
    <div className={cx(styles.componentBase, className)}>
      <audio ref={audioRef}>
        {src && <source type="audio/mpeg" {...{ src }} />}
      </audio>

      <PlayButton {...{ isPlaying }} layoutClassName={styles.playButtonLayout} onClick={handleClick} />
      <TextButton {...{ isPlaying }} onClick={handleClick} />
    </div>
  )

  function handleClick() {
    setIsPlaying(!isPlaying)

    if (isPlaying) {
      src && audioRef.current.pause()
    } else {
      src && audioRef.current.play()
    }
  }
}

function PlayButton({ isPlaying, layoutClassName, onClick }) {
  return (
    <button
      className={cx(styles.componentPlayButton, layoutClassName)}
      type='button'
      data-x='toggle-play-audio'
      {...{ onClick }}
    >
      <Icon icon={isPlaying ? pauseIcon : playIcon} />
      <PulsatingRings active={isPlaying} layoutClassName={styles.ringLayout} />
    </button>
  )
}

function TextButton({ isPlaying, onClick }) {
  const { __ } = useTranslate()

  return (
    <button
      className={styles.componentTextButton}
      type='button'
      data-x='toggle-play-audio'
      {...{ onClick }}
    >
      <FadingLabel visible={!isPlaying} label={__`listen-to-the-poem`} />
      <FadingLabel visible={isPlaying} label={__`stop-listening`} />
    </button>
  )
}

function PulsatingRings({ active, layoutClassName }) {
  const style = useSpring({ opacity: active ? 1 : 0 })
  return (
    <animated.div className={cx(styles.componentPulsatingRings, layoutClassName)} {...{ style }}>
      <Ring layoutClassName={styles.ringLayout} />
      <Ring layoutClassName={styles.ringLayout} />
    </animated.div>
  )
}

function Ring({ layoutClassName }) {
  return <div className={cx(styles.componentRing, layoutClassName)} />
}

function FadingLabel({ visible, label }) {
  return <p style={{ opacity: visible ? 1 : 0 }} className={styles.componentFadingLabel}> {label} </p>
}
