import { useLocalStorage } from '/machinery/useLocalStorage'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { extractArticles } from '/features/regionArticles/extractArticles'

export function useArticlesWithReadState({ articles = [] } = {}) {
  const [articlesRead, updateArticlesRead] = useLocalStorage('articles_read', {})

  return React.useMemo(
    () => {
      const normalizedArticles = extractArticles(articles)
        .map(article => ({
          read: articlesRead[normalizeId(article.id)],
          ...article
        }))

      return {
        articles: normalizedArticles,
        handleArticleRead
      }

      function handleArticleRead(articleId) {
        updateArticlesRead(x => ({ ...x, [articleId]: true }))

        pushToDataLayer({
          event: 'article_read',
        })
      }
    },
    [articles, articlesRead, updateArticlesRead]
  )
}

function normalizeId(idOrIds) {
  if (Array.isArray(idOrIds))
    return idOrIds.join(',')
  return idOrIds
}
