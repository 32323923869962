import { useSpring, animated } from '@react-spring/web'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Logo.css'

import logo from '/images/branding/rabo-en-co-logo.svg'

export function Logo({ isBig = false, subtitle = undefined, hasGradient = false, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const logoWidthAsBig = isViewportLg ? 230 : 200

  const style = useSpring({
    width: isBig ? logoWidthAsBig : 100,
    config: { mass: 1, tension: 280, friction: 60 }
  })

  const styleText = useSpring({
    scale: isBig ? 1 : 0.5,
    config: { mass: 1, tension: 280, friction: 60 }
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <animated.img src={logo} width='200px' alt="logo" className={styles.logo} {...{ style }} />
      {subtitle && <animated.h1 style={styleText} className={styles.text}>{subtitle}</animated.h1>}
      {hasGradient && <div className={styles.backgroundGradient} />}
    </div>
  )
}
