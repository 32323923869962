import { animated } from '@react-spring/web'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useParams } from '/machinery/Params'

import { IssueSlider } from '/features/buildingBlocks/IssueSlider'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { ContainerLg } from '/features/buildingBlocks/Container'

import mediaStyles from '/cssGlobal/media.css'
import styles from './CardUitgelezenContent.css'

import mapIcon from '/images/icons/map.raw.svg'

export function CardUitgelezenContent({ title, layoutClassName, issues, animations = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.headingAndButton}>
        <Heading animation={animations && animations.headingGroup} {...{ title }} />
        <Button animation={animations && animations.ctaButton} />
      </div>
      {Boolean(issues?.length) && (
        <animated.div style={animations && animations.imageScale}>
          <ContainerLg>
            <IssueSlider
              initialSlide={0}
              origin={isViewportMd ? 0 : 'center'}
              slidesPerView={isViewportMd ? 3 : 1.2}
              {...{ issues }}
            />
          </ContainerLg>
        </animated.div>
      )}
    </div>
  )
}

function Heading({ title, animation = undefined, layoutClassName = undefined }) {
  return (
    <animated.h2 style={animation} className={cx(styles.componentHeading, layoutClassName)}>
      {title}
    </animated.h2>
  )
}

function Button({ animation = undefined }) {
  const { __ } = useTranslate()
  const { language } = useParams()

  return (
    <animated.div style={animation ? animation : undefined} className={styles.componentButton}>
      <ButtonLinkPrimary
        label={__`discover-other-regions`}
        href={routeMap.app.home({ language })}
        dataX='link-to-kaart'
        icon={mapIcon}
      />
    </animated.div>
  )
}
