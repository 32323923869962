import { useFloating, useDismiss, useInteractions, FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useElementSize } from '@kaliber/use-element-size'

import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { mergeRefs } from '/machinery/mergeRefs'

import { ButtonLinkSecondary } from '/features/buildingBlocks/Button'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'
import { ShareButton } from '/features/buildingBlocks/ShareButton'
import { IssueSlider } from '/features/buildingBlocks/IssueSlider'
import { FooterToc } from '/features/pageOnly/Footer'
import { HeadingSm } from '/features/buildingBlocks/Heading'

import mediaStyles from '/cssGlobal/media.css'
import styles from './DeckToc.css'

import arrowLeft from '/images/icons/arrow-left.raw.svg'

export function DeckToc({ isOpen, issues, onClose, coverImage, articles, region, renderDeckItemComponent, layoutClassName = undefined }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      if (!open) onClose()
    }
  })

  const dismiss = useDismiss(context, { capture: false })

  const { getFloatingProps } = useInteractions([ dismiss ])

  return (
    <div aria-hidden={!isOpen} className={cx(styles.component, layoutClassName)}>
      <FloatingOverlayBase lockScroll={isOpen} className={cx(styles.floatingOverlay, isOpen && styles.overlayActive)}>
        <Drawer
          floatingProps={getFloatingProps()}
          layoutClassName={styles.deckIndexLayout}
          {...{ coverImage, articles, isOpen, onClose, refs, region, issues, renderDeckItemComponent }}
        />
      </FloatingOverlayBase>
    </div>
  )
}

function Drawer({ coverImage, articles, isOpen, onClose, refs, floatingProps, region, issues, renderDeckItemComponent, layoutClassName }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const amountOfItems = articles.length
  const { x, elementRef } = useDeckIndexGestures({ isOpen })

  const bind = useDrag(
    ({ swipe: [swipeX], movement: [movementX] }) => {
      if (swipeX === -1 || movementX < -150) {
        onClose()
      }
    }, { axis: 'x' }
  )

  return (
    <animated.nav
      ref={mergeRefs(elementRef, refs.setFloating)}
      style={{ x }}
      className={cx(styles.componentDrawer, layoutClassName)}
      {...bind()}
      {...floatingProps}
    >
      <div className={styles.deckIndexHeader}>
        <button type='button' onClick={onClose} data-x='click-to-close-toc' className={styles.backButton}>
          <Icon icon={arrowLeft} layoutClassName={styles.iconLayout} />
        </button>

        <ShareButton
          title={__({ region })`share-title`}
          type='region'
          text={__`share-text`}
          shareText={__`share-share-text`}
          image={coverImage}
          layoutClassName={styles.shareButtonLayout}
          {...{ region }}
        />

        <ImageCover image={coverImage} aspectRatio={isViewportMd ? 16 / 9 : 9 / 16} layoutClassName={styles.deckIndexHeaderImageLayout} />

        <h2 className={styles.regionIndexTitle}>{region}</h2>

        <div className={styles.deckIndexAmount}>
          <span>{__({ amount: amountOfItems })`amount-of-articles`}</span>
        </div>
      </div>

      <ul>
        {articles.map((x, idx) => renderDeckItemComponent({ item: x }, idx))}
      </ul>

      {Boolean(issues?.length) && (
        <div className={styles.issueSliderContainer}>
          <IssueSlider
            title={__`read-other-editions`}
            slidesPerView={1.2}
            initialSlide={0}
            {...{ issues }}
          />
        </div>
      )}

      <BecomeMember />

      <FooterToc />
    </animated.nav>
  )
}

function useDeckIndexGestures({ isOpen }) {
  const { size: { width }, ref: elementRef } = useElementSize()

  const [{ x }, api] = useSpring(() => ({
    x: '-100%',
  }))

  React.useEffect(
    () => {
      api.start({
        x: isOpen ? '0%' : '-100%',
      })
    },
    [isOpen, api, width]
  )

  return { x, elementRef }
}

function BecomeMember() {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentBecomeMember}>
      <HeadingSm h={3}>{__`become-member-title`}</HeadingSm>

      <p className={styles.description}>{__`become-member-description`}</p>

      <ButtonLinkSecondary
        href='https://www.rabobank.nl/leden'
        label={__`become-member-button-title`}
        dataX='link-to-become-member'
        layoutClassName={styles.buttonLayout}
      />
    </div>
  )
}
