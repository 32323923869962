import { useTranslate } from '/machinery/I18n'

import { ButtonIconWithLabel } from '/features/buildingBlocks/Button'
import { Image } from '/features/buildingBlocks/Image'
import { ZoomableImagePopup } from '/features/regionArticles/cards/popups/ZoomableImagePopup'

import styles from './ZoomableImageArticle.css'

import plus from '/images/icons/icon-plus.raw.svg'

export function ZoomableImageArticle({ image, handlePopupActiveChange = undefined, layoutClassName = undefined }) { // TODO: Erik - `= undefined` for popupActiveChange is needed for woodwing content, but woodwing content could also be used in cards where we need to tell if popups are active in order to disable stuff
  const [active, setActive] = React.useState(null)

  return (
    <>
      <ImageWithButton {...{ image, layoutClassName }} onClick={handleOpenClick} />
      <ZoomableImagePopup {...{ image, active }} onClose={handleCloseClick} />
    </>
  )

  function handleOpenClick() {
    setActive(true)
    if (handlePopupActiveChange) handlePopupActiveChange(true)
  }

  function handleCloseClick() {
    setActive(false)
    if (handlePopupActiveChange) handlePopupActiveChange(false)
  }
}

function ImageWithButton({ image, onClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentImageWithButton, layoutClassName)}>
      <Image layoutClassName={styles.imageLayout} {...{ image }} />
      <ButtonIconWithLabel
        label={__`check-in-detail`}
        dataX='open-detail-kunstcollectie-view'
        icon={plus}
        layoutClassName={styles.buttonLayout}
        {...{ onClick }}
      />
    </div>
  )
}
