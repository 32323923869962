import { useReportError } from '/machinery/ReportError'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerMd } from '/features/buildingBlocks/Container'
import { ButtonLinkPrimary, ButtonLinkSecondary } from '/features/buildingBlocks/Button'
import { RevealingQuote, RevealingQuoteWithImage } from '/features/regionArticles/RevealingQuote'
import { ImageSlider } from '/features/article/buildingBlocks/ImageSlider'
import { Video } from '/features/article/buildingBlocks/Video'
import { AudioPlayerArticlePage } from '/features/gedicht/buildingBlocks/AudioPlayer'
import { LoopingVideoAll } from './article/pageOnly/LoopingVideo'
import { ParallaxImage } from '/features/buildingBlocks/ParallaxImage'
import { ZoomableImageArticle } from '/features/buildingBlocks/ZoomableImageArticle'
import { Crediter } from '/features/buildingBlocks/Crediter'
import { PhotoIconOverlay } from './buildingBlocks/PhotoIconOverlay'
import { Caption } from '/features/buildingBlocks/Caption'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Woodwing.css'

import arrowRight from '/images/icons/chevron-right.raw.svg'

const components = {
  'cta': {
    'snackable': ButtonLinkSecondary,
    'default': ButtonLinkPrimary,
  },
  'quote': {
    'default': RevealingQuote,
  },
  'quoteWithImage': {
    'default': RevealingQuoteWithImage,
  },
  'imageSlider': {
    'default': ImageSlider,
  },
  'video': {
    'default': Video,
  },
  'audio': {
    'default': AudioPlayerArticlePage, // TODO: this is probably problematic as a default
  }
}

const layoutComponents = {
  'wrapper': {
    page({ layoutClassName, template, identifier, children }) {
      const Container = ShouldRenderFullWidthOnPage(template) ? React.Fragment : ContainerMd
      return (
        <Section dataX={template} {...{ layoutClassName }}>
          <Container> {children} </Container>
        </Section>
      )
    },
  },
}

function ShouldRenderFullWidthOnPage(template) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return ['quoteWithImage', 'imageSlider', !isViewportMd && 'kader'].includes(template) // names here should be the normalized names
}

/**
 * @param {{
 *   contentItems: any,
 *   layoutClassName?: string,
 *   styleId?: 'default' | 'snackable' | 'page' | 'conclusion' | 'none',
 *   layoutStyleId?: 'default' | 'page' | 'snackable',
 * }} props
 */
export function WoodwingContent({ contentItems, styleId = 'default', layoutStyleId = 'default', layoutClassName = undefined }) {
  const reportError = useReportError()
  const wrapper = layoutComponents.wrapper[layoutStyleId]

  return (
    <div className={cx(styles.componentContent, styles[`${styleId}Base`], styles[layoutStyleId], layoutClassName)}>
      {contentItems.map((item, i) =>
        <React.Fragment key={i}>
          {renderContentItem(item, { styleId, reportError, wrapper })}
        </React.Fragment>
      )}
    </div>
  )
}

/**
 * @param {{
 *   item: any,
 *   layoutClassName?: string,
 *   styleId?: 'default' | 'snackable' | 'page' | 'none',
 * }} props
*/
export function WoodwingContentItem({ item, styleId = 'default', layoutClassName = undefined }) {
  const reportError = useReportError()

  return renderContentItem(item, { styleId, reportError, layoutClassName })
}

function renderContentItem(item, { styleId, reportError, wrapper = undefined, layoutClassName = undefined }) {
  const { identifier } = item
  const template = determineTemplate(identifier, { reportError })
  layoutClassName = layoutClassName || styles[`${template}Layout`]

  return renderWrappedElement({ item, template, identifier, styleId, wrapper, reportError, layoutClassName })
}

function determineTemplate(identifier, { reportError }) {
  switch (identifier) {
    // TODO: Lars - Once Woodwing data is received take a second look at the mapping and what is required and what isn't
    // TODO: https://kaliber.atlassian.net/browse/RABOCO-120
    case 'photographer': // TODO: Erwin - implement display of photographer
    case 'author': // TODO: Erwin - implement display of author //TODO: Erik - I think the Author may be rendered in the tags component
      return 'crediter'
    case 'body':
      return 'paragraphs'
    case 'button':
      return 'cta'
    case 'intro':
      return 'intro'
    case 'subheading':
      return 'heading'
    case 'quote':
      return 'quote'
    case 'image-quote':
      return 'quoteWithImage'
    case 'image':
      return 'image'
    case 'kaliber-image-slider':
    case 'slideshow':
      return 'imageSlider'
    case 'embed-video':
      return 'video'
    case 'embed-gif':
      return 'gif'
    case 'embed-audio': // TODO: Lars - Ik kom bij audio veel varianten tegen met geen of een \" in de src, komt dat door ons of door Woodwing?
      return 'audio'
    case 'kaliber-list':
      return 'list'
    case 'conclusioncontainer':
      return 'conclusion'
    case 'framecontainer':
      return 'kader'
    case 'kaliber-poem':
      return 'gedicht'
    case 'position':
      return 'position'
    case 'name':
      return 'name'
    case 'subtitle':
      return 'subtitle'
    case 'zoomeble': // zoomeble -> zoomable
      return 'zoomable'
    // TODO: Erik - check if these exist
    case 'crediter':
    case 'fact-list':
    case 'description-list':
    case 'hero-image-secondary':
    case 'hero-image':
    default: {
      reportError(new Error(`Unknown content identifier '${identifier}'`))
      return 'unknown'
    }
  }
}

function renderWrappedElement({ item, identifier, template, styleId, wrapper, reportError, layoutClassName }) {
  const element = renderContentElement(
    item,
    { styleId, reportError, template, layoutClassName: wrapper ? undefined : layoutClassName }
  )

  if (!wrapper) return element

  const Wrapper = wrapper
  return <Wrapper {...{ identifier, template, layoutClassName }}>{element}</Wrapper>
}

function renderContentElement(item, { styleId, template, reportError, layoutClassName }) {
  // TODO: Erik / Lars - some content is invalid, do we need to check everywhere here or will we remove invalid content during normalization?
  if (!item.content && ['audio'].includes(template)) return null

  switch (template) {
    case 'text': return (
      <WoodwingText text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'paragraphs': return (
      <WoodwingParagraphs paragraphs={item.paragraphs} {...{ styleId, layoutClassName }} />
    )
    case 'cta': return ( // TODO: Lars / Erwin - in the previous version the CallToAction component was used with title and description
      <WoodwingCta text={item.text} href={item.href} {...{ styleId, layoutClassName }} />
    )
    case 'intro': return (
      <WoodwingIntro paragraphs={item.paragraphs} {...{ styleId, layoutClassName }} />
    )
    case 'heading': return (
      <WoodwingHeading text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'subtitle': return (
      <WoodwingSubtitle text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'name': return (
      <WoodwingName text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'position': return (
      <WoodwingPosition text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'quote': return (
      <WoodwingQuote text={item.text} {...{ styleId, layoutClassName }} />
    )
    case 'quoteWithImage': return (
      <WoodwingQuoteWithImage
        quote={item.quote}
        image={item.image}
        caption={item.caption}
        photographer={item.photographer}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'zoomable': return (
      <WoodwingZoomable
        image={item.image}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'image': return (
      <WoodwingImage
        image={item.image}
        caption={item.caption}
        photographer={item.photographer}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'imageSlider': return (
      <WoodwingImageSlider images={item.images} {...{ styleId, layoutClassName }} />
    )
    case 'video': return (
      // TODO: Erik / Erwin - hier missen we nog velden: description, image
      // TODO: Lars - ik zag in de src van de test data een \", zetten wij die erin of is dat een fout bij Woodwing?
      <WoodwingVideo video={item.src} poster={item.image} description={item.description} {...{ styleId, layoutClassName }} />
    )

    case 'gif': return (
      // TODO: Erik / Erwin - hier missen we nog velden: description, image
      <WoodwingGif src={item.src}  orientation={item.orientation} {...{  layoutClassName }} />
    )

    case 'audio': return (
      // TODO: Lars - ik zag in de src van de test data een \", zetten wij die erin of is dat een fout bij Woodwing?
      <WoodwingAudio audio={item.content.src} {...{ styleId, layoutClassName }} />
    )
    case 'list': return (
      // TODO: Lars - ik zag in de src van de test data een \", zetten wij die erin of is dat een fout bij Woodwing?
      <WoodwingList items={item.items} {...{ styleId, layoutClassName }} />
    )
    case 'conclusion': return (
      <WoodwingConclusion
        title={item.title}
        introParagraphs={item.introParagraphs}
        contentItems={item.contentItems}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'gedicht': return (
      <WoodwingGedicht lines={item.lines} {...{ styleId, layoutClassName }} />
    )
    case 'kader': return (
      <WoodwingKader
        title={item.title}
        contentItems={item.contentItems}
        {...{ styleId, layoutClassName }}
      />
    )
    case 'crediter': return (
      <Crediter position={item.identifier} name={item.text} />
    )
    case 'unknown': return null
    default: {
      // TODO: Erik - this can be removed as soon as we have everything implemented
      const identifier = template.split('-').slice(1).join('-')
      if (identifier) return (
        <p style={{ backgroundColor: 'red' }} className={layoutClassName}>
          [NOT IMPLEMENTED - identifier: {identifier}]
        </p>
      )

      reportError(new Error(`Unknown template '${template}'`))
      return null
    }
  }
}

function WoodwingTextBase({ text, styleId, className, layoutClassName = undefined }) {
  return (
    <span className={cx(styles.componentTextBase, className, styles[styleId], layoutClassName)}>
      {text}
    </span>
  )
}

function WoodwingText({ text, styleId, layoutClassName = undefined }) {
  return <WoodwingTextBase className={styles.componentText} {...{ text, styleId, layoutClassName }} />
}

function WoodwingSubtitle({ text, styleId, layoutClassName = undefined }) {
  return <WoodwingTextBase className={styles.componentSubtitle} {...{ text, styleId, layoutClassName }} />
}

function WoodwingName({ text, styleId, layoutClassName = undefined }) {
  return <WoodwingTextBase className={styles.componentName} {...{ text, styleId, layoutClassName }} />
}

function WoodwingPosition({ text, styleId, layoutClassName = undefined }) {
  return <WoodwingTextBase className={styles.componentPosition} {...{ text, styleId, layoutClassName }} />
}

function WoodwingParagraphs({ paragraphs, styleId, layoutClassName = undefined }) {
  return (
    <WoodwingParagraphsBase
      className={styles.componentParagraphs}
      {...{ paragraphs, layoutClassName, styleId }}
    />
  )
}

function WoodwingIntro({ paragraphs, styleId, layoutClassName = undefined }) {
  return (
    <WoodwingParagraphsBase
      className={styles.componentIntro}
      {...{ paragraphs, layoutClassName, styleId }}
    />
  )
}

function WoodwingParagraphsBase({ paragraphs, className, layoutClassName, styleId }) {
  return paragraphs.map((paragraph, i) =>
    <p key={i} className={cx(className, styles[styleId], layoutClassName)}>
      <WoodwingParagraph {...{ paragraph }} />
    </p>
  )
}

function WoodwingParagraph({ paragraph }) {
  return paragraph.flatMap((content, i) => {
    const { type, lines } = content
    return (
      type === 'lines' ? <WoodwingLines key={i} {...{ lines }} /> :
      type === 'link' ? <WoodwingParagraphLink key={i} href={content.href} {...{ lines }} /> :
      null
    )
  })
}

function WoodwingParagraphLink({ href, lines }) {
  return (
    <a {...{ href }} className={styles.componentParagraphLink} data-x='link-to-external'>
      <WoodwingLines {...{ lines }} />
    </a>
  )
}

function WoodwingLines({ lines }) {
  const linesWithBreaks = lines.flatMap((line, i) => [<br />, line]).slice(1)

  return linesWithBreaks.map((x, i) =>
    <React.Fragment key={i}>{x}</React.Fragment>
  )
}

function WoodwingHeading({ text, styleId, layoutClassName = undefined }) {
  return (
    <h2 className={cx(styles.componentHeading, styles[styleId], layoutClassName)}>
      {text}
    </h2>
  )
}

function WoodwingCta({ text, href, styleId, layoutClassName = undefined }) {
  const Component = getComponent('cta', styleId)

  return (
    <Component
      label={text}
      dataX='link'
      icon={arrowRight}
      {...{ href, layoutClassName }}
    />
  )
}

function WoodwingQuote({ text, styleId, layoutClassName = undefined }) {
  const Component = getComponent('quote', styleId)
  return <Component quote={text} {...{ layoutClassName }} />
}

function WoodwingQuoteWithImage({ caption, image, photographer, quote, styleId, layoutClassName = undefined }) {
  const Component = getComponent('quoteWithImage', styleId)

  return (
    <div className={cx(styles.componentQuoteWithImage, layoutClassName)}>
      <Component {...{ quote, image, layoutClassName }} />
      <div className={styles.captionContainer}>
        <PhotoIconOverlay
          layoutClassName={styles.captionLayout}
          renderContent={() =>
            <Caption caption={[photographer, caption].filter(Boolean).join(' - ')} />
          }
        />
      </div>
    </div>
  )
}

function WoodwingImage({ caption, image, photographer, layoutClassName }) {
  return (
    <div className={cx(styles.componentImage, layoutClassName)}>
      <ParallaxImage {...{ image, layoutClassName }} />
      <div className={styles.captionContainer}>
        <PhotoIconOverlay
          layoutClassName={styles.captionLayout}
          renderContent={() =>
            <Caption caption={[photographer, caption].filter(Boolean).join(' - ')} />
          }
        />
      </div>
    </div>
  )
}

function WoodwingZoomable({ image, layoutClassName = undefined }) {
  return <ZoomableImageArticle {...{ image, layoutClassName }} />
}

function WoodwingImageSlider({ images, styleId, layoutClassName = undefined }) {
  const Component = getComponent('imageSlider', styleId)
  // TODO: Erik / Erwin - hier missen denk ik ook de captions en credits
  return <Component images={images.map(({ image, caption, photographer }) => ({ image, caption, photographer }))} {...{ layoutClassName }} />
}

function WoodwingVideo({ video, poster, description, styleId, layoutClassName = undefined }) {
  const Component = getComponent('video', styleId)
  return <Component src={video} {...{ layoutClassName, poster, description }} />
}

function WoodwingAudio({ audio, styleId, layoutClassName = undefined }) {
  const Component = getComponent('audio', styleId)
  return <Component src={audio} {...{ layoutClassName }} />
}

function WoodwingList({ items, layoutClassName, styleId }) {
  return (
    <ul className={cx(styles.componentList, styles[styleId], layoutClassName)}>
      {items.map((item, i) =>
        <WoodwingListItem key={i} {...{ item, styleId }} />
      )}
    </ul>
  )
}

function WoodwingGif({ src, orientation }) {
  return <LoopingVideoAll {...{ src, orientation }} />
}

function WoodwingListItem({ item, styleId }) {
  return (
    <li className={cx(styles.componentListItem, styles[styleId])}>
      <WoodwingParagraphs paragraphs={item.paragraphs} {...{ styleId }} />
    </li>
  )
}

function WoodwingConclusion({ title, introParagraphs, contentItems, styleId, layoutClassName }) {
  return (
    <div className={cx(styles.componentConclusion, styles[styleId], layoutClassName)}>
      <WoodwingHeading text={title} styleId='conclusion' />
      <WoodwingIntro paragraphs={introParagraphs} styleId='conclusion' />
      <WoodwingContent {...{ contentItems }} styleId='conclusion' />
    </div>
  )
}

export function WoodwingKader({ title = undefined, contentItems, styleId, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentKader, styles[styleId], layoutClassName)}>
      {title && <WoodwingHeading text={title} {...{ styleId }} />}
      <WoodwingContent {...{ contentItems }} styleId='default' />
    </div>
  )
}

function WoodwingGedicht({ lines, layoutClassName, styleId }) {
  return (
    <div className={cx(styles.componentGedicht, styles[styleId], layoutClassName)}>
      {lines.map((line, i) =>
        <WoodwingPoemLine key={i} {...{ line, styleId }} layoutClassName={styles.poemLineLayout} />
      )}
    </div>
  )
}

function WoodwingPoemLine({ line, styleId, layoutClassName }) {
  return <p className={cx(styles.componentPoemLine, styles[styleId], layoutClassName)}>{line}</p>
}

function Section({ dataX, layoutClassName, children }) {
  return (
    <section data-x={dataX} className={layoutClassName} >
      {children}
    </section>
  )
}

/**
 * @template {keyof components} T
 * @param {T} name
 * @param {string} styleId
 * @returns {typeof components[T][keyof components[T]]}
 */
function getComponent(name, styleId) {
  const componentsForName = components[name]
  return componentsForName[styleId] || componentsForName['default']
}
