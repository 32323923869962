import { useKeenSlider } from 'keen-slider/react'
import { animated, useSpring } from '@react-spring/web'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { stopPropagationEventHandlers } from '/machinery/stopPropagationEventHandlers'

import { ImageCover } from '/features/buildingBlocks/Image'
import { PhotoIconOverlay } from '/features/buildingBlocks/PhotoIconOverlay'
import { Caption } from '/features/buildingBlocks/Caption'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ImageSlider.css'

export function ImageSlider({ images }) {
  const { ref, isInViewport } = useIsInViewport()
  const { instanceRef, carouselRef, currentSlide, intervalSeconds } = useKeenSliderRefs({ images, isInViewport })

  const currentImage = images[currentSlide]
  const currentCaption = [currentImage.photographer, currentImage.caption].filter(Boolean).join(' - ')

  return (
    <section data-x='image-slider' className={styles.component} {...{ ref }}>
      <div ref={carouselRef} className={styles.carousel}>
        <div {...stopPropagationEventHandlers()} className='keen-slider'>
          {images?.map(({ image }, i) => (
            <div key={i} className={cx(styles.slide, 'keen-slider__slide')}>
              <ImageSlide {...{ image }} />
            </div>
          ))}
        </div>

        <div className={styles.captionContainer}>
          <PhotoIconOverlay
            layoutClassName={styles.captionLayout}
            renderContent={() => <Caption caption={currentCaption} />}
          />
        </div>

        <div className={styles.slideBarsContainer}>
          {images.map((_, i) => (
            <SlideBar
              key={i}
              slideId={i}
              onSlideChange={handleSlideChange}
              layoutClassName={styles.slideBarLayout}
              {...{ currentSlide, intervalSeconds }}
            />
          ))}
        </div>
      </div>
    </section>
  )

  function handleSlideChange(idx) {
    instanceRef.current?.moveToIdx(idx)
  }
}

function ImageSlide({ image }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  return (
    <div className={styles.componentImageSlide}>
      <ImageCover aspectRatio={isViewportMd ? 16 / 9 : 3 / 4} imgProps={{ loading: 'lazy' }} {...{ image }} />
    </div>
  )
}

function SlideBar({ currentSlide, slideId, onSlideChange, intervalSeconds, layoutClassName = undefined }) {
  const style = useSpring({
    reset: true,
    from: { scaleX: 0 },
    to: async (next) => {
      if (slideId <= currentSlide) {
        await next({ scaleX: 1 })
      } else {
        await next({ scaleX: 0 })
      }
    },
    config: { duration: currentSlide === slideId ? 1000 * intervalSeconds : 0 }
  })

  return (
    <button
      type='button'
      onClick={() => onSlideChange(slideId)}
      data-x='click-to-slide'
      className={cx(styles.componentSlideBar, layoutClassName)}
    >
      <div className={styles.progressBar}>
        <animated.span className={styles.progressFill} {...{ style }} />
      </div>
    </button>
  )
}

function useKeenSliderRefs({ images, isInViewport }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const intervalRef = React.useRef(null)
  const intervalSeconds = 6

  const [carouselRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    slideChanged: (s) => {
      setCurrentSlide(s.track.details.rel)
      trackDragInteraction('slide_changed')
    },
    dragStarted() { trackDragInteraction('drag_started') },
    dragEnded() { trackDragInteraction('drag_ended') }
  })

  React.useEffect(
    () => {
      if (instanceRef.current.size === 1) instanceRef.current.update()
    }
  )

  React.useEffect(
    () => {
      intervalRef.current = setInterval(() => {
        isInViewport && instanceRef.current?.moveToIdx((currentSlide + 1) % images.length)
      }, 1000 * intervalSeconds)

      return () => clearInterval(intervalRef.current)
    },
    [instanceRef, currentSlide, isInViewport, images.length]
  )

  function trackDragInteraction(action) {
    trackInteraction({
      action,
      title: 'interaction',
      type: 'drag'
    })
  }

  return { instanceRef, carouselRef, currentSlide, intervalSeconds }
}
