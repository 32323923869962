import ReactPlayerBase from 'react-player'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { useEvent } from '/machinery/useEvent'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { Icon } from '/features/buildingBlocks/Icon'
import { ImageCover } from '/features/buildingBlocks/Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Video.css'

import playIcon from '/images/icons/play.raw.svg'

export function Video({ src, poster, description = undefined }) {
  const isMounted = useRenderOnMount()
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const videoRef = React.useRef(null)
  const [playing, setPlaying] = React.useState(false)
  const handleVideoTrackingEvent = useEvent(handleVideoTracking)

  React.useEffect(
    () => {
      if (playing && !isInViewport) {
        setPlaying(false)
        handleVideoTrackingEvent('scrolled out of viewport', 'scroll') // TODO: RABOCO-391 Erik / Erwin - grote kans dat dit niet klopt doordat dit een use effect is
      }
    },
    [playing, isInViewport, handleVideoTrackingEvent]
  )

  return (
    <div ref={isInViewportRef} className={styles.component}>
      <div className={styles.playerContainer}>
        {isMounted && (
          <ReactPlayerBase
            controls
            url={src}
            ref={videoRef}
            width="100%"
            height="100%"
            onPlay={handlePlay}
            onPause={handlePause}
            onSeek={() => handleVideoTracking('seek', 'click')}
            onEnded={() => handleVideoTracking('finished', 'ended')}
            className={styles.player}
            {...{ playing }}
          />
        )}
        <button
          type='button'
          data-x='play-video'
          onClick={handlePlay}
          className={cx(styles.playButton, playing && styles.playing)}
        >
          <PlayButton layoutClassName={styles.playButtonLayout} />
        </button>
        {poster && !playing && <Poster layoutClassName={styles.posterLayout} {...{ poster }} />}
      </div>
      {description && <p className={styles.description}>{description}</p>}
    </div>
  )

  function handlePlay() {
    setPlaying(true)
    handleVideoTracking('play', 'click')
  }

  function handlePause() {
    if (videoRef.current?.getInternalPlayer().seeking) return

    setPlaying(false)
    handleVideoTracking('pause', 'click')
  }

  function handleVideoTracking(action, type) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackInteraction({
      title: 'video',
      action,
      type,
      index: 1,
      extraMetaData: {
        video: {
          video: src,
          duration,
          currentTime,
          progress
        }
      }
    })
  }
}

function Poster({ layoutClassName, poster }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <div className={cx(styles.componentPoster, layoutClassName)}>
      <ImageCover
        image={poster}
        aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
        {...{ layoutClassName }}
      />
    </div>
  )
}

function PlayButton({ layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentPlayButton, layoutClassName)}>
      <Icon icon={playIcon} layoutClassName={styles.iconLayout} />
      <span className={styles.buttonLabel}>Bekijk de video</span>
    </div>
  )
}
