import { useDrag } from '@use-gesture/react'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function useDrawerGestures({ dragGesturesEnabled, dismissGestureInstruction = undefined, instructionsDismissed = undefined }) {
  const [tocIsOpen, setTocIsOpen] = React.useState(false)
  const [searchIsOpen, setSearchIsOpen] = React.useState(false)

  const tocDrawer =  {
    isOpen: tocIsOpen,
    handleOpen: () => {
      if (tocIsOpen) return

      setSearchIsOpen(false)
      setTocIsOpen(true)

      instructionsDismissed !== true && dismissGestureInstruction && dismissGestureInstruction()
    },
    handleClose: () => {
      if (!tocIsOpen) return

      setTocIsOpen(false)
    }
  }

  const searchDrawer = {
    isOpen: searchIsOpen,
    handleOpen: () => {
      if (searchIsOpen) return

      setSearchIsOpen(true)
      setTocIsOpen(false)
    },
    handleClose: () => {
      if (!searchIsOpen) return

      setSearchIsOpen(false)
    },
  }

  const bind = useGestures({ dragGesturesEnabled, tocDrawer, searchDrawer })

  return { bind, searchDrawer, tocDrawer }
}

function useGestures({ dragGesturesEnabled, tocDrawer, searchDrawer }) {
  const [drawerIsActive, setDrawerIsActive] = React.useState(false)

  const bind = useDrag(
    ({ swipe: [swipeX], movement: [movementX], down }) => {
      if (!dragGesturesEnabled) return

      const selectedText = window.getSelection().toString()
      if (selectedText.length > 0) return

      if (!down) setDrawerIsActive(tocDrawer.isOpen || searchDrawer.isOpen)

      if (swipeX === 1 || movementX > 150) {
        if (drawerIsActive) return handleCloseSearch()
        else handleOpenToc()
      } else if (swipeX === -1 || movementX < -150) {
        if (drawerIsActive) return handleCloseToc()
        else handleOpenSearch()
      }
    }, { axis: 'x' }
  )

  return bind


  function handleOpenToc() {
    if (tocDrawer.isOpen) return

    tocDrawer.handleOpen()
    trackInteraction({ title: 'open toc', action: 'open', type: 'drag' })
  }

  function handleCloseToc() {
    tocDrawer.handleClose()
    setDrawerIsActive(false)
    trackInteraction({ title: 'close toc', action: 'close', type: 'drag' })
  }

  function handleOpenSearch() {
    if (searchDrawer.isOpen) return

    searchDrawer.handleOpen()
    trackInteraction({ title: 'open search', action: 'open', type: 'drag' })
  }

  function handleCloseSearch() {
    searchDrawer.handleClose()
    setDrawerIsActive(false)
    trackInteraction({ title: 'close search', action: 'close', type: 'drag' })
  }
}
