import { useIsActiveSignal } from '/machinery/IsActive'
import { useSignalDrivenSpring } from '/machinery/useSignalDrivenSpring'

const config = { mass: 1, tension: 280, friction: 60 }

export function useCardAnimations() {
  const isActive$ = useIsActiveSignal()

  const headingGroup = useSignalDrivenSpring(isActive$, isActive => ({
    y: isActive ? 0 : 50,
    opacity: isActive ? 1 : 0,
    config,
    delay: 200
  }))

  const navigationButton = useSignalDrivenSpring(isActive$, isActive => ({
    x: isActive ? 0 : -50,
    opacity: isActive ? 1 : 0,
    config,
    delay: 400
  }))

  const ctaButton = useSignalDrivenSpring(isActive$, isActive => ({
    y: isActive ? 0 : 100,
    opacity: isActive ? 1 : 0,
    config,
    delay: 300
  }))

  const metaData = useSignalDrivenSpring(isActive$, isActive => ({
    y: isActive ? 0 : 100,
    config,
    delay: 300
  }))

  const imageScale = useSignalDrivenSpring(isActive$, isActive => ({
    scale: isActive ? 1 : 1.4,
    config
  }))

  return { metaData, navigationButton, headingGroup, imageScale, ctaButton }
}
