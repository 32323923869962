import { useFloating, FloatingPortal, useDismiss, FloatingFocusManager, useInteractions, FloatingOverlay } from '@floating-ui/react'
import { animated, useSpring } from '@react-spring/web'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Modal.css'

import closeIcon from '/images/icons/mark.raw.svg'

export function ModalMd({ active, onClose, content, colorClassName = undefined, contentContainerClass = undefined }) {
  return <ModalBase containerSizeClass={'containerMd'} {...{ active, onClose, content, colorClassName, contentContainerClass }} />
}

export function ModalXl({ active, onClose, content, colorClassName = undefined, contentContainerClass = undefined }) {
  return <ModalBase containerSizeClass={'containerXl'} {...{ active, onClose, content, colorClassName, contentContainerClass }} />
}

function ModalBase({ active, onClose, content, colorClassName, contentContainerClass, containerSizeClass }) {
  const { style } = useModalAnimations(active)

  const { refs, context } = useFloating({
    open: active,
    onOpenChange: onClose
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([ dismiss ])

  return (
    active && (
      <FloatingPortal>
        <FloatingFocusManager modal initialFocus={1} {...{ context }}>
          <FloatingOverlay
            // eslint-disable-next-line @kaliber/layout-class-name, @kaliber/naming-policy
            className={cx(styles.overlay, styles[colorClassName], styles[contentContainerClass])}
            lockScroll={active}
            {...getReferenceProps()}
          >
            <animated.div
              ref={refs.setFloating}
              aria-hidden={!active}
              className={cx(styles._rootModal)}
              {...getFloatingProps()}
              {...{ style }}
            >
              <div className={cx(styles.modalContainer, styles[containerSizeClass])}>
                {content}
                <CloseButton onClick={onClose} layoutClassName={styles.closeButtonLayout} />
              </div>
            </animated.div>
          </FloatingOverlay>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  )
}

function CloseButton({ onClick, layoutClassName = undefined }) {
  const style = useSpring({
    from: {
      scale: 0.8
    },
    to: {
      scale: 1
    },
    config: { mass: 1, tension: 280, friction: 60 },
    delay: 200
  })

  return (
    <animated.button
      type='button'
      data-x='close-modal'
      className={cx(styles.componentCloseButton, layoutClassName)}
      {...{ onClick, style }}
    >
      <Icon icon={closeIcon} layoutClassName={styles.closeIconLayout} />
    </animated.button>
  )
}

function useModalAnimations(active) {
  const [style, api] = useSpring(() => ({
    opacity: 0,
    y: 100,
  }))

  React.useEffect(() => {
    api.start({
      opacity: active ? 1 : 0,
      y: active ? 0 : 100,
    })
  }, [active, api])

  return {
    style
  }
}
