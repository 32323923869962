import { Icon } from '/features/buildingBlocks/Icon'

import styles from './TagButton.css'

export function TagButton({ buttonId, onClick, label, dataX, icon = undefined, layoutClassName = undefined }) {
  const buttonVariant = buttonId % 3

  switch (buttonVariant) {
    case 0: return <TagButtonGreen {...{ onClick, label, dataX, icon, layoutClassName }} />
    case 1: return <TagButtonCream {...{ onClick, label, dataX, icon, layoutClassName }} />
    case 2: return <TagButtonCyan {...{ onClick, label, dataX, icon, layoutClassName }} />
    default: return <TagButtonGreen {...{ onClick, label, dataX, icon, layoutClassName }} />
  }
}

function TagButtonGreen({ onClick, label, dataX, icon, layoutClassName }) {
  return <TagButtonBase className={cx(styles.componentGreen, layoutClassName)} {...{ onClick, label, dataX, icon }} />
}

function TagButtonCream({ onClick, label, dataX, icon, layoutClassName }) {
  return <TagButtonBase className={cx(styles.componentCream, layoutClassName)} {...{ onClick, label, dataX, icon }} />
}

function TagButtonCyan({ onClick, label, dataX, icon, layoutClassName }) {
  return <TagButtonBase className={cx(styles.componentCyan, layoutClassName)} {...{ onClick, label, dataX, icon }} />
}

function TagButtonBase({ onClick, label, dataX, icon, className }) {
  return (
    <button data-x={dataX} className={cx(styles.componentBase, className)} {...{ onClick }}>
      <p className={styles.label}>
        {label}
      </p>
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
    </button>
  )
}
