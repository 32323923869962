import { useTranslate } from '/machinery/I18n'

import { Logo } from '/features/regionArticles/Logo'

import styles from './Loader.css'

export function Loader({ layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <LoaderCircleWhite layoutClassName={styles.circleLayout} />
      <Logo hasGradient subtitle={__`loading`} />
    </div>
  )
}

function LoaderCircleWhite({ layoutClassName = undefined }) {
  return (
    <LoaderCircleBase {...{ layoutClassName }}>
      <div className={styles.componentCircleWhite} />
    </LoaderCircleBase>
  )
}

export function LoaderCircleGray({ layoutClassName }) {
  return (
    <LoaderCircleBase {...{ layoutClassName }}>
      <div className={styles.componentCircleGray} />
    </LoaderCircleBase>
  )
}

function LoaderCircleBase({ children, layoutClassName }) {
  return (
    <div className={cx(styles.componentCircleBase, layoutClassName)}>
      {children}
    </div>
  )
}
