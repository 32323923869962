import { reportClientError } from '/machinery/reportClientError'
import { routeMap } from '/routeMap'

export async function getEsSearchResult({ searchString = undefined, bank = undefined, region = undefined, issue = undefined, tags = [], language, size = undefined }) {
  if (!searchString && tags.length === 0) return {}

  const body = {
    filters: {
      searchString,
      region,
      bank,
      issue,
      tags
    },
    ...(size && { size }),
    language
  }

  return apiCall(routeMap.api.v1.search(), { body })
}

export async function getEsSearchWithExcludedRegionsResult({ searchString = undefined, bank = undefined, region = undefined, issue = undefined, tags = [], language, size = undefined }) {
  if (!searchString && tags.length === 0) return {}

  const body = {
    filters: {
      searchString,
      region,
      bank,
      issue,
      tags
    },
    ...(size && { size }),
    language
  }

  return apiCall(routeMap.api.v1.searchWithExcludedRegions(), { body })
}

export async function getEsEditionTags({ bank, region, issue, language }) {
  const body = {
    filters: {
      region,
      bank,
      issue
    },
    language
  }

  return apiCall(routeMap.api.v1.getTags(), { body })
}

export async function getEsSearchResultWithinAndNotWithinRegion({ bank, region, tags, language, issue = undefined, searchString = undefined, size = undefined }) {
  const withinRegion = await getEsSearchResult({ searchString, bank, region, tags, language, issue, size: 999 })
  const notWithinRegion = await getEsSearchWithExcludedRegionsResult({ searchString, bank, region, tags, language, issue, size })

  return { withinRegion, notWithinRegion }
}


async function handleSearchResponse(response) {
  const responseText = await response.text()
  if (!response.ok)
    throw new Error(`Search request failed. Got status ${response.status}\n ${responseText}`)

  return JSON.parse(responseText)
}

async function apiCall(url, { body }) {
  try {
    const response = await fetch(url, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })

    const handledResponse = await handleSearchResponse(response)
    return handledResponse
  } catch (e) {
    reportClientError(e)
    return {}
  }
}
